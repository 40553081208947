import React from 'react'
import style from './content-wrapper.m.scss'
import herdOutsideSmall from "Resource/our-herd.jpg";
import {Section} from "./section";
import workshops from 'Resource/workshops.jpg'
import rider from 'Resource/rider-horse.jpg'
import {useScreenBreakpoint} from "hooks/use-media";
import {useTranslation} from "react-i18next";


export const ContentWrapper = () => {
    const {t} = useTranslation()

    const isBigScreen = useScreenBreakpoint(760)

    return <div className={style.root}>
        <div className={style.content}>
            {
                isBigScreen ?
                    <img className={style.herdOutside}
                         alt={'herd outside'}
                         src={herdOutsideSmall}
                         loading={'lazy'}>
                    </img> :
                    <img className={style.herdOutside}
                         alt={'herd outside'}
                         src={herdOutsideSmall}
                         loading={'lazy'}>

                    </img>
            }
            <span className={style.title}>{t('peacefulPaddocks')}</span>
            <span className={style.paragraph}>{t('homePageContent.peacefulPaddocksParagraph')}</span>
            <div className={style.sectionWrapper}>
                <Section
                    img={workshops}
                    title={t('workshops')}
                    text={t('homePageContent.workshopsParagraph')}/>
                <Section
                    img={rider}
                    title={t('education')}
                    text={t('homePageContent.educationParagraph')}/>
            </div>
        </div>
    </div>
}
