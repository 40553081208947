import React from 'react'
import style from './description.m.scss'
import {useTranslation} from "react-i18next";

export const Description = () => {
    const {t} = useTranslation()
    return <div className={style.root}>
        <p>
            {t('description')}
        </p>
    </div>
}
