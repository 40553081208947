import React from 'react'
import {Route, Routes} from 'react-router-dom'
import {HomePage} from "./pages/home/home-page";
import {AboutUs} from "./pages/about-us/about-us";
import {PeacefulPaddocks} from "./pages/peaceful-paddocks/peaceful-paddocks";
import {Blog} from "./pages/blog/blog";
import {Supporters} from "./pages/supporters/supporters";

export const EquishpereRoutes = () => (
    <Routes>
        <Route path='/' element={<HomePage/>}/>
        {/*<Route path='/aboutUs' element={<AboutUs/>}/>
        <Route path='/peacefulPaddocks' element={<PeacefulPaddocks/>}/>
        <Route path='/blog' element={<Blog/>}/>
        <Route path='/supporters' element={<Supporters/>}/>*/}
    </Routes>
)

