import React from 'react'
import style from './section.m.scss'

export const Section = ({title, img, text}) => {
    return <div className={style.root}>
        <img alt={'image'} src={img} className={style.image} loading={'lazy'}/>
        <h1 className={style.header}>{title}</h1>
        <p className={style.paragraph}>{text}</p>
    </div>
}
