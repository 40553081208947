import React, {useState} from 'react'
import {HamburgerButton} from "components/hamburger-button";
import {NavigationMenu} from "components/navigation-menu";
import style from "./header.m.scss"
import {useScreenBreakpoint} from "hooks/use-media";
import {LanguageSelect} from "components/language-select";

export const Header = () => {

    const [isNavOpen, setIsNavOpen] = useState(false)
    const shouldShowHorizontalMenu = useScreenBreakpoint(760)

    return <header>
       {/* {
            shouldShowHorizontalMenu
                ? <NavigationMenu isVertical={false}/>
                : <HamburgerButton onClick={() => setIsNavOpen(old => !old)}/>
        }
        {isNavOpen ? <NavigationMenu isVertical={true} onSelect={() => setIsNavOpen(old => !old)}/> : null}
    */}<LanguageSelect/>
    </header>
}
