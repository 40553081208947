import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import en from './en.json'
import hr from './hr.json'

// All locales supported by the app
export const Locale = {
    EN: 'en',
    HR: 'hr'
}

// Initializes i18n and returns translation function.
export const initI18n = async () => {
    try {
        return i18n.use(initReactI18next).init({
            resources: {
                hr: {
                    translation: hr
                },
                en: {
                    translation: en
                }
            },
            debug: false,
            lng: 'en',
            fallbackLng: ['hr', 'en'],
            interpolation: {
                escapeValue: false
            }
        })
    } catch (error) {
        console.error(error)
        throw new Error('Couldn\'t initialize internationalization library.')
    }
}

