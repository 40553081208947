import React, {useContext, useEffect, useState} from 'react'
import style from 'components/language-select.m.scss'
import i18next from "i18next";
import {Locale} from "i18n";
import {useTranslation} from "react-i18next";

export const LanguageSelect = () => {
    const [lang, setLang] = useState(Locale.EN)
    const {t} = useTranslation()

    useEffect(() => {
        i18next.changeLanguage(lang)
    }, [lang])

    return <select value={lang} onChange={({target: {value}}) => setLang(value)}>
            <option value={Locale.HR}>{t('croatian')}</option>
            <option value={Locale.EN}>{t('english')}</option>
        </select>
}
