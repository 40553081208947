import React from 'react'
import style from 'components/hamburger-button.m.scss'

export const HamburgerButton = ({...props }) => {

    return (
        <button
            title={'navigation'}
            className={style.root}
            {...props}
        />
    )
}
