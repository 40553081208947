import React from 'react'
import style from './footer.m.scss'
import facebook from "Resource/facebook.png";
import instagram from "Resource/instagram.png";
import email from "Resource/email.png";
import location from "Resource/location.png";

export const Footer = () => {
    return <footer className={style.root}>
        <a href="https://www.facebook.com/profile.php?id=61551348583963">
            <img alt={'facebookIcon'} src={facebook} className={style.icon}/>
        </a>
        <a href="https://instagram.com/equisphere_?igshid=YTQwZjQ0NmI0OA==">
            <img alt={'instagram'} src={instagram} className={style.icon}/>
        </a>
        <a href="mailto:kristina@equisphere.hr">
            <img alt={'email'} src={email} className={style.icon}/>
        </a>
        <a href="https://maps.app.goo.gl/PJCCb2NJWnnjuKnw6">
            <img alt={'location'} src={location} className={style.icon}/>
        </a>
    </footer>
}
