import React, {useEffect, useState} from "react";
import {initI18n} from "i18n";
import {RootCtx} from "root-context";


export const RootWrapper = ({ children }) => {
    const [initializing, setInitializing] = useState(true)

    useEffect(() => {
        initI18n().then(
            () => { setInitializing(false) },
            error => { setInitializing(false)
        })
    }, [])

    if (initializing) return null

    return (
        <RootCtx.Provider value={'jurica'}>
            {children}
        </RootCtx.Provider>
    )
}
