import React from 'react'
import {Banner} from "pages/home/banner";
import {Description} from "./description";
import {ContentWrapper} from "./content-wrapper";


export const HomePage = () => {
    return <div>
        <Banner/>
        <Description/>
        <ContentWrapper/>
    </div>
}
