import React from 'react'
import style from 'pages/home/banner.m.scss'
import herd from 'Resource/herd.jpg'
import herdSmall from 'Resource/herd-small.jpg'
import logo from 'Resource/logo.png'
import {useScreenBreakpoint} from "/src/hooks/use-media";
import {useTranslation} from "react-i18next";


export const Banner = () => {
    const isBigScreen = useScreenBreakpoint(760)
    const {t} = useTranslation()
    return <div className={style.root}>
        {
            isBigScreen
                ?
                <img alt={'herd eating'} src={herd} className={style.banner} loading={'lazy'}></img>
                :
                <img alt={'herd eating'} src={herdSmall} className={style.banner} loading={'lazy'}></img>
        }
        <div className={style.moto1}><h1>{`"${t('moto1')}`}</h1></div>
        <div className={style.moto2}><h1>{`${t('moto2')}"`}</h1></div>
        <img alt={'equisphere logo'} src={logo} className={style.logo} loading={'lazy'}></img>
    </div>
}

