import React from 'react'
import {createRoot} from 'react-dom/client'
import {EquishpereRoutes} from "equishpere-routes";
import {BrowserRouter} from "react-router-dom";
import './style/global.scss'
import {Layout} from "pages/home/layout";
import {RootWrapper} from "root-wrapper";

createRoot(document.getElementById('root')).render(
    <BrowserRouter>
        <RootWrapper>
            <Layout>
                <EquishpereRoutes/>
            </Layout>
        </RootWrapper>
    </BrowserRouter>
)
