import React from 'react'
import style from './layout.m.scss'
import {Header} from "components/header";
import {Footer} from "./footer";

export const Layout = ({children}) => {
    return <div className={style.root}>
        <Header/>
        {children}
        <Footer/>
    </div>
}
